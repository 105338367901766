<template>
<v-container>

  <div class="ma-n4 text-center">
    <img alt="EquityApproved.ca Logo" src="../assets/logosvg.svg" style="max-width: 750px; margin-top: 100px;">
    <br><br>
    <p style="max-width: 75%; margin-left: auto; margin-right: auto;">
 Lending opportunity information is only accessible by the unique link provided to you. Please refer the lending opportunity email and contact us if you have any issues.
    </p>


  </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {},
};
</script>
